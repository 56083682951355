/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

/**
 *  Base class allowing to keep and update model,
 *  and subscribe on model changes
 */


export interface IDisposable {
  dispose: () => void;
}


export class _BaseObservable<M> {
  protected _model: M;
  private _subscriptions: ((m: M) => any)[] = [];

  constructor(model: M) {
    this._model = model;
  }

  public getModel(): M {
    return this._model;
  }

  public subscribeUpdates(callback: (m: M) => any): IDisposable {
    this._subscriptions.push(callback);
    return {
      dispose: () => {
        this.unsubscribeUpdates(callback);
      }
    }
  }

  public subscribeUpdatesAndNotify(callback: (m: M) => any): IDisposable {
    const subscription: IDisposable = this.subscribeUpdates(callback);
    callback(this.getModel());
    return subscription;
  }

  public unsubscribeUpdates(callback: (m: M) => any): void {
    const idx = this._subscriptions.indexOf(callback);
    if (idx !== -1) {
      this._subscriptions.splice(idx, 1);
    }
  }

  protected _updateModel(partialModel: Partial<M>) {
    const prevModel: M = this._model;
    this._model = {
      ...(this._model as any),              // Spread types may only be created from object types.
      ...(partialModel as any),
    };
    // TODO: check changed
    this._notifySubscriptions();
  }

  protected _notifySubscriptions() {
    let subscriptions = this._subscriptions.slice(0);
    const model = this._model;
    for(let s of subscriptions) {
      if (this._subscriptions.indexOf(s) !== -1) {
        try {
          s(model);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }
}
