/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import {_BaseObservable, IDisposable} from "../common/_BaseObservable";
import {IRoute, ROUTES} from "../common/utils";


function urlIncludes(routeUrl: string, currentUrl: string): boolean {
  return routeUrl.startsWith(currentUrl) &&
    (routeUrl[currentUrl.length] === '/' || routeUrl[currentUrl.length - 1] === '/');
}


function getRouteByUrl(url: string): IRoute | null {
  for (let route of ROUTES) {
    if (route.path === url) {
      return route;
    }
  }

  if (url === '' || url == '/') {                                                                   // find entry point
    let route = ROUTES.find(r => !!r.isEntryPoint);
    if (route) {
      return route;
    }
  }

  // find by common start
  for (let route of ROUTES) {
    if (route.path && urlIncludes(route.path, url)) {
      return route;
    }
  }

  return null;
}


export interface IAppState {
  millis: [number, number];
  currentRoute: IRoute | null;
}


function extractRoute(): IRoute | null {
  let hash: string = window.location.hash || '';
  if (hash[0] === '#') hash = hash.slice(1);
  const route: IRoute | null = getRouteByUrl(hash);
  return route;
}


export class AppStateInteractor extends _BaseObservable<IAppState> {
  private constructor() {
    super({
      millis: [new Date().valueOf() - 1000 * 60 * 60, new Date().valueOf()],             // last 1 hour
      currentRoute: null,
    });
    window.addEventListener('hashchange', this._onHashChanged, false);
    this._onHashChanged();
  }

  private _onHashChanged = async () => {
    let hash: string = window.location.hash || '';
    if (hash[0] === '#') hash = hash.slice(1);
    const currentRoute: IRoute | null = getRouteByUrl(hash);
    console.log('_onHashChanged', currentRoute);
    this._updateModel({currentRoute});

    if (currentRoute === null && hash[0] === '/') {
      try {
        const page = await import(`../views/routes/${hash.slice(1)}`);
        // page exists
        this._updateModel({
          currentRoute: {
            path: hash,
            title: hash.split('/').slice(1).map(p => p[0].toUpperCase() + p.slice(1)).reverse().join(' '),
          },
        });

      } catch (err) {
        // not found: 404
        this._updateModel({currentRoute: null});
      }
    }
  };

  private static _instance: AppStateInteractor | null;

  public static getInstance(): AppStateInteractor {
    if (!this._instance) {
      this._instance = new AppStateInteractor();
    }
    return this._instance;
  }

  public static getModel(): IAppState {
    return this.getInstance().getModel();
  }

  public static subscribeUpdates(callback: (m: IAppState) => any): IDisposable {
    return this.getInstance().subscribeUpdates(callback);
  }

  public static unsubscribeUpdates(callback: (m: IAppState) => any): void {
    this.getInstance().unsubscribeUpdates(callback);
  }
}
