/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import * as React from "react";
import cn from "classnames";
import {AuthenticationInteractor} from "../../interactors/AuthenticationInteractor";
import {LoadingSpinner} from "../LoadingSpinner/LoadingSpinner";
import {AppStateInteractor, IAppState} from "../../interactors/AppStateInteractor";
import "./PageAuth.scss";

const SVG: any = require('react-inlinesvg').default;                // no @types

const VERSION = '2021.1.1';

const FORGOT_PASSWORD_LINK = "mailto:abuse@localhost?Subject=Change%20Password&amp;body=Please%2C%20change%20my%20password.%0A%0AThank%20you.";


const AuthFooter = () => (
  <footer className="AuthFooter row">
    <hr/>
    <div className="AuthFooter__Content">
      <small>
        <strong>Copyright &copy; 2017-2020 <a href="https://sip3.io"><b>SIP3</b>.IO</a></strong> All rights reserved.
      </small>
      <small>
        <b>Version:</b>
        <span> </span>
        <span>{VERSION}</span>
      </small>
    </div>
  </footer>);


interface IAuthFormProps {
  error: string | null;
  onFocus?: (elementId: string) => void;
  onPressSignIn: (username: string, password?: string) => void;
}


class AuthForm extends React.PureComponent<IAuthFormProps> {
  props: IAuthFormProps;
  state: {
    username: string;
    password: string;
  };

  private _usernameInput: HTMLInputElement;

  constructor(props: IAuthFormProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  public componentDidMount() {
    this._usernameInput.focus();
  }

  private _onPressSignIn = (event: any) => {
    this.props.onPressSignIn(this.state.username, this.state.password);
    event.stopPropagation();
    event.preventDefault();
  };

  private _onUsernameChange = (event: any) => {
    this.setState({username: (event.target.value || '').toLowerCase()});
  };

  private _onPasswordChange = (event: any) => {
    this.setState({password: event.target.value});
  };

  private _onFocusUsername = (event: any) => {
    if (this.props.onFocus) {
      this.props.onFocus('username');
    }
  };

  private _onFocusPassword = (event: any) => {
    if (this.props.onFocus) {
      this.props.onFocus('password');
    }
  };

  private _onBlur = (event: any) => {
    if (this.props.onFocus) {
      this.props.onFocus('');
    }
  };

  public render() {
    const {error} = this.props;
    const {username, password} = this.state;
    return (
      <form className="AuthForm m-t" role="form" action="index.html">
        {!!error && <div className="AuthForm__Error form-group">
          {error}
        </div>}
        <div className="AuthForm__UsernameContainer form-group">
          <input className="AuthForm__Username form-control"
                 ref={ref => {
                   this._usernameInput = ref as HTMLInputElement
                 }}
                 type="text"
                 value={username}
                 placeholder="Username"
                 required={true}
                 onChange={this._onUsernameChange}
                 onFocus={this._onFocusUsername}
                 onBlur={this._onBlur}/>
        </div>
        <div className="AuthForm__PasswordContainer form-group">
          <input className="AuthForm__Password form-control"
                 type="password"
                 value={password}
                 placeholder="Password"
                 required={true}
                 onChange={this._onPasswordChange}
                 onFocus={this._onFocusPassword}
                 onBlur={this._onBlur}/>
        </div>
        <a className="AuthForm__ForgotPassword link" href={FORGOT_PASSWORD_LINK}>
          <small>Forgot password?</small>
        </a>
        <button className="AuthForm__SignIn btn btn btn-primary block m-b"
                type="submit"
                onClick={this._onPressSignIn}>
          Sign in
        </button>

        <a className="AuthForm__SignUp" href="#/signup">
          <small>Sign up</small>
        </a>
      </form>);
  }
}


class PageSignup extends React.PureComponent {
  public render() {
    return (
      <section className="PageSignup">
        <h2 className="PageSignup__Title">Create account</h2>
        <hr/>

        <form className="container PageSignup__Form"
              role="login"
              name="signupForm"
              noValidate={false}>

          <div className="col">
            <div className="form-group">
              <input type="text" id="rc-new-first-name" name="firstName" placeholder="First Name"
                     className="form-control input-lg"
                     required={true} autoFocus={false}/>
            </div>
            <div className="form-group">
              <input type="text" id="rc-new-last-name" name="lastName" placeholder="Last Name"
                     className="form-control input-lg"
                     required={true}/>
            </div>
            <div className="form-group">
              <input type="email" id="rc-new-email" name="email" placeholder="Email address"
                     className="form-control input-lg"
                     required={true}/>
            </div>
            <div className="form-group">
              <input type="password" id="rc-new-password" name="password" placeholder="Password"
                     className="form-control input-lg"
                     minLength={5} required={true}/>
            </div>
            <div className="form-group">
              <input type="text" id="rc-new-domain" name="domain" placeholder="Domain Name"
                     className="form-control input-lg"
                     maxLength={255}
                     pattern="^[a-zA-Z0-9-]+$"
                     required={true}/>
            </div>

            <button className="rc-btn btn btn-primary sign-in-btn" id="signup-button">Create Account</button>

            <br/>

            &nbsp;

            <p className="text-muted">
              <small>Already have an account? <a className="link" href="#/"> Sign in </a></small>
            </p>
          </div>

        </form>
      </section>);
  }
}


export class PageAuth extends React.PureComponent<{}> {
  public state: {
    loading: boolean;
    error: string | null;
    focused: string;
    signup: boolean;
  };

  private _asi: AppStateInteractor;

  public constructor(props: {}) {
    super(props);
    this._asi = AppStateInteractor.getInstance();
    const appState = this._asi.getModel();
    this.state = {
      loading: false,
      error: null,
      focused: '',
      signup: !!(appState.currentRoute && appState.currentRoute.path === '/signup'),
    };
  }

  public componentDidMount() {
    this._asi.subscribeUpdates(this._onAppStateChanged);
    const appState = this._asi.getModel();
    this.setState({
      signup: !!(appState.currentRoute && appState.currentRoute.path === '/signup'),
    });
  }

  public componentWillUnmount() {
    this._asi.unsubscribeUpdates(this._onAppStateChanged);
  }

  private _onAppStateChanged = (appState: IAppState) => {
    this.setState({
      signup: !!(appState.currentRoute && appState.currentRoute.path === '/signup'),
    });
  };

  private _onPressSignIn = async (username: string, password: string) => {
    this.setState({loading: true, error: null,});
    try {
      await AuthenticationInteractor.getInstance().signIn(username, password);
    } catch (err) {
      this.setState({loading: false, error: String(err)});
    }
  };

  private _onFocus = (elementId: string) => {
    this.setState({focused: elementId});
  };

  public render() {
    const {loading, error, focused, signup} = this.state;

    return (
      <div className={cn('PageAuth loginColumns animated fadeInDown', {
        UsernameFocused: (focused === 'username'),
        PasswordFocused: (focused === 'password'),
        signup,
      })}>
        <div className="PageAuth__Wrapper">
          <h2 className="PageAuth__Title">Welcome to SIP3.IO</h2>
          <div className="PageAuth__Content">
            <p className="PageAuth__LogoContainer"/>
            {signup ?
              <PageSignup/> :
              <div className="PageAuth__FormContainer">
                <div className="ibox-content">
                  <h3 className="PageAuth__FormContainerTitle">Sign in to your account</h3>
                  <LoadingSpinner showSpinner={loading}>
                    <AuthForm onPressSignIn={this._onPressSignIn}
                              error={error}
                              onFocus={this._onFocus}/>
                  </LoadingSpinner>
                </div>
              </div>
            }
          </div>
        </div>

        <AuthFooter/>
      </div>);
  }
}
